import {
  QueryClientProvider,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { modals } from '@mantine/modals';
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import packageService from "../services/package.service";
import moment from "moment";
import { Box, Button, Divider, Flex, LoadingOverlay, Text } from "@mantine/core";
import { Package } from "../Interfaces";

const usePackages = () => {
  return useQuery({
    queryKey: ["id"],
    queryFn: async () => {
      const { data } = await packageService.getPackages();
      console.log(data);
      return data["data"];
    },
    placeholderData: keepPreviousData, //useful for paginated queries by keeping data from previous pages on screen while fetching the next page
    // staleTime: 30_000, //don't refetch previously viewed pages until cache is more than 30 seconds old
  });
};

const PackagesPage = () => {
  // const [packages, setPackages] = useState([]);
  const { status, data, error, isFetching, refetch } = usePackages();
  const [isCanceling, setIsCanceling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: "number",
          header: "Number",
        },
        {
          accessorKey: "createdAt",
          header: "Date",
          Cell: ({ cell }: any) => (
            <span>{moment(cell.getValue()).format("lll")}</span>
          ),
        },
        {
          header: "Status",
          muiTableHeadCellProps: { sx: { color: "green" } },
          accessorFn: (row) => {
            const status = row.status as string;
            return status.charAt(0).toUpperCase() + status.slice(1);
          }
        },
        {
          accessorKey: "recipient",
          header: "Recipient",
          muiTableHeadCellProps: { sx: { color: "green" } },
          Cell: ({ row }: any) => (
            <span>
              {row.original.recipientLastName +
                " " +
                row.original.recipientFirstName}
            </span>
          ),
        },

        // {
        //   accessorFn: (row) => row.age, //alternate way
        //   id: "age", //id required if you use accessorFn instead of accessorKey
        //   header: "Age",
        //   Header: <i style={{ color: "red" }}>Age</i> //optional custom markup
        // }
      ] as MRT_ColumnDef<Package>[],
    [],
  );

  let printIframe: any = null;
  let blobUrls: any = [];

  const openCancelModal = (packageId: string) =>
    modals.openConfirmModal({
      title: 'Cancel package',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to cancel this package? This can't be undone.
        </Text>
      ),
      labels: { confirm: 'Cancel package', cancel: "No don't cancel this package" },
      confirmProps: { color: 'red' },
      // onCancel: () => cancelPackage(packageId),
      onConfirm: () => cancelPackage(packageId),
      lockScroll: true,

    });

  const cancelPackage = async (packageId: string) => {
    setIsCanceling(true);
    setIsLoading(true);
    try {
      const response = await packageService.cancelPackage(packageId);
      // if (response.status !== 200) {
  
      // }
      refetch();
    }
    catch (_error: any) {
      console.log(_error.response)
    }
    finally {
      setIsCanceling(false);
      setIsLoading(false);
    }
  }

  const printBase64PDF = async (packageLabel: string | null | undefined) => {
    if (packageLabel) {
      if (!printIframe) {
        printIframe = document.createElement("iframe");
        document.body.appendChild(printIframe);

        printIframe.style.position = "absolute";
        printIframe.style.top = "0";
        printIframe.style.left = "-1000px";

        printIframe.onload = () => {
          setTimeout(() => {
            printIframe?.focus();
            printIframe?.contentWindow?.print();
          }, 100);
        };
      }

      // convert base64 to a blob - using data URLs directly produces CORS errors
      const resp = await fetch(`data:application/pdf;base64,${packageLabel}`);
      const blob = await resp.blob();
      const url = URL.createObjectURL(blob);
      blobUrls.push(url);

      printIframe.src = url;
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <MantineReactTable
        columns={columns}
        data={data ? data : []}
        enableExpandAll={false}
        renderDetailPanel={({ row }) => (
          <Box>
            <Box
              style={{
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "1fr 1fr 1fr",
                width: "100%",
              }}
            >
              <Text>Length: {row.original.length} inch</Text>
              <Text>Width: {row.original.width} inch</Text>
              <Text>Height: {row.original.height} inch</Text>
              <Text>Weight: {row.original.weight} lbs</Text>
              <Text>Billing Weight: {row.original.billingWeight} lbs</Text>
              <Text>Price: ${row.original.totalPrice}</Text>
              <Text>Declared Value: ${row.original.declaredValue}</Text>
            </Box>
            <Flex
              align={"center"}
              justify={"center"}
              gap={5}
              mt={10}
            >
              <Button
                onClick={() => printBase64PDF(row.original.label)}
                style={{}}
                bg={"#FF0000"}
                disabled={row.original.status === "canceled"}
              >
                Print label
              </Button>
              <Button
                onClick={() => openCancelModal(row.original.id as string)}
                style={{}}
                bg={"#FF0000"}
                disabled={row.original.status !== "new"}
                loading={isCanceling}
                loaderProps={{ type: 'dots' }}
              >
                Cancel package
              </Button>
              <Button
                style={{}}
                bg={"#FF0000"}
                disabled={row.original.status === "canceled"}
                onClick={() => window.open(`https://www.fedex.com/wtrk/track/?action=track&tracknumbers=${row.original.number}&locale=en_US&cntry_code=us`, "_blank")}
              >
                Track package
              </Button>
            </Flex>
            <Divider
              my="md"
              labelPosition="center"
              label={<Text>Sender</Text>}
            />
            <Box
              style={{
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "1fr 1fr 1fr",
                width: "100%",
              }}
            >
              <Text>First name: {row.original.senderFirstName}</Text>
              <Text>Last name: {row.original.senderLastName}</Text>
              <Text />
              <Text>Address: {row.original.senderAddressLine1}</Text>
              <Text>
                Address (second line): {row.original.senderAddressLine2}
              </Text>
              <Text>ZIP code: {row.original.senderPostcode}</Text>
              <Text>City: {row.original.senderCity}</Text>
              <Text>State: {row.original.senderState}</Text>
              <Text>Country: {row.original.senderCountry}</Text>
              <Text>Phone Number: {row.original.senderPhoneNumber}</Text>
              <Text>Email: {row.original.senderEmail}</Text>
            </Box>
            <Divider
              my="md"
              labelPosition="center"
              label={<Text>Recipient</Text>}
            />
            <Box
              style={{
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "1fr 1fr 1fr",
                width: "100%",
              }}
            >
              <Text>First name: {row.original.recipientFirstName}</Text>
              <Text>Last name: {row.original.recipientLastName}</Text>
              <Text />
              <Text>Address: {row.original.recipientAddressLine1}</Text>
              <Text>
                Address (second line): {row.original.recipientAddressLine2}
              </Text>
              <Text>ZIP code: {row.original.recipientPostcode}</Text>
              <Text>City: {row.original.recipientCity}</Text>
              <Text>State: {row.original.recipientState}</Text>
              <Text>Country: {row.original.recipientCountry}</Text>
              <Text>Phone Number: {row.original.recipientPhoneNumber}</Text>
              <Text>Email: {row.original.recipientEmail}</Text>
            </Box>
          </Box>
        )}
        // displayColumnDefOptions={{
        //   'mrt-row-numbers': {
        //     size: 10,
        //   },
        //   'mrt-row-expand': {
        //     size: 10,
        //   },
        // }}
        // // enableColumnActions={!onlyOptions}
        // enableColumnFilterModes
        enablePagination={true}
        // enableColumnPinning
        // enableRowNumbers
        // enableBottomToolbar={false}
        // // enableTopToolbar={!onlyOptions}
        // initialState={{
        //   columnVisibility: { description: false },
        //   density: 'xs',
        //   showGlobalFilter: true,
        //   sorting: [{ id: 'tableInstanceAPI', desc: false }],
        // }}
        // mantineSearchTextInputProps={{
        //   placeholder: 'Search Table APIs',
        //   style: { minWidth: '18rem' },
        //   variant: 'filled',
        // }}
        // mantinePaperProps={{
        //   style: { marginBottom: '24px' },
        //   id: onlyOptions
        //     ? 'relevant-table-instance-apis-table'
        //     : 'table-instance-apis-table',
        // }}
        positionGlobalFilter="right"
        // renderDetailPanel={({ row }) => (
        //   <Text color={row.original.description ? 'teal' : 'gray'}>
        //     {row.original.description || 'No Description Provided... Yet...'}
        //   </Text>
        // )}
        // rowNumberMode="static"
        // onColumnPinningChange={setColumnPinning}
        // state={{ columnPinning }}
      />
    </QueryClientProvider>
  );
};

export default PackagesPage;
