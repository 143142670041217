import {
  Flex,
  Burger,
  Button,
  AppShell,
  Text,
} from "@mantine/core";
import { ReactComponent as LogoHeader } from "../assets/images/logo-header.svg";
import { ReactComponent as Logout } from "../assets/images/logout.svg";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Header = ({ toggle, opened }: any) => {
  const navigate = useNavigate();

  const logout = () => {
    AuthService.logout();
    navigate("/login");
  };  

  const getUsername = () => {
    const user = AuthService.getCurrentUser();
    if (user.company) {
      return `${user.email} (${user.company})` as string
    }
    else {
      return user.email as string
    }
  };

  return (
    <AppShell.Header bg="#FF0000">
      <Flex
        justify="space-between"
        align="center"
        style={{ padding: "10px 20px 10px 20px" }}
      >
        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        <LogoHeader />
        <Flex justify={"center"} align={"center"} gap={8}>
          <Text c={"#FFF"}>Logged as: {getUsername()}</Text>
          <Button
            size="sm"
            fw={500}
            fs={"12px"}
            variant="link"
            onClick={logout}
            bg={"#FFFFFF"}
          >
            <Flex justify={"center"} align={"center"} gap={8}>
              <Logout />
              <Text c={"#FF0000"}>Log out</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </AppShell.Header>
  );
};

export default Header;
