import { useEffect, useState } from "react";
import { State } from "country-state-city";
import { useForm, isNotEmpty, isEmail, isInRange } from "@mantine/form";
import {
  Button,
  Group,
  TextInput,
  Text,
  NumberInput,
  Box,
  Flex,
  Title,
  Select,
  Switch,
  Collapse,
  Input,
  InputWrapper,
  Radio,
  Divider,
  Loader,
  Checkbox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import classes from "./Worldco.module.css";
import { AddressValidation, Package } from "../Interfaces";
import senderService from "../services/sender.service";
import { SenderRecipientAutocomplete } from "./SenderRecipientAutocomplete";
import { IMaskInput } from "react-imask";
import packageService from "../services/package.service";
import { IconDimensions, IconWeight } from "@tabler/icons-react";
import { isValidPhoneNumber } from "react-phone-number-input";

type Props = {
  nextStep: () => void;
  packageData: Package;
  setPackageData: (arg0: Package) => void;
};

const AddPackageForm = ({ packageData, setPackageData, nextStep }: Props) => {
  const [senderFormVisible, senderFormVisibleHandler] = useDisclosure(
    !packageData.useLastSender,
  );
  const [
    recipientAddressClassificationRadio,
    recipientAddressClassificationRadioHandler,
  ] = useDisclosure(false);
  const [
    senderAddressClassificationRadio,
    senderAddressClassificationRadioHandler,
  ] = useDisclosure(false);
  const [senderId, setSenderId] = useState<string | null>(null);
  const [lastSenderString, setLastSenderString] = useState<string | null>(null);
  const [recipient, setRecipient] = useState<string | null>(null);
  const [goToNextStepDirectly, setGoToNextStepDirectly] = useState(false);
  const [isValidatingAddress, setIsValidatingAddress] = useState(false);
  const [isLoadingSenderZip, setIsLoadingSendertZip] = useState(false);
  const [isLoadingRecipientZip, setIsLoadingRecipientZip] = useState(false);

  const states = State.getStatesOfCountry("US");
  var statesToSelect = [];

  for (var state of states) {
    statesToSelect.push({
      value: state.isoCode,
      label: state.name,
    });
  }

  useEffect(() => {
    const getLastSender = async () => {
      try {
        const response = await senderService.getLast();
        setSenderId(response.data["data"]["id"]);
        setLastSenderString(
          `${response.data["data"].firstName} ${
            response.data["data"].lastName
          }, ${response.data["data"].addressLine1}${
            response.data["data"].addressLine2
              ? " " + response.data["data"].addressLine2
              : ""
          }, ${response.data["data"].postcode} ${response.data["data"].city}, ${
            response.data["data"].state
          }`,
        );
        packageForm.setValues({
          senderFirstName: response.data["data"].firstName,
          senderLastName: response.data["data"].lastName,
          senderCompany: response.data["data"].company,
          senderAddressLine1: response.data["data"].addressLine1,
          senderAddressLine2: response.data["data"].addressLine2,
          senderPostcode: response.data["data"].postcode,
          senderCity: response.data["data"].city,
          senderState: response.data["data"].state,
          senderPhoneNumber: response.data["data"].phoneNumber,
          senderEmail: response.data["data"].email,
          senderAddressClassification:
            response.data["data"].addressClassification,
          senderAddressClassificationByFedex:
            response.data["data"].addressClassificationByFedex,
        });
      } catch {
        senderFormVisibleHandler.open();
      }
    };
    if (packageData.useLastSender) {
      getLastSender();
    }
  }, []);

  const validatePhoneNumber = (value: string) => {
    if (!isValidPhoneNumber(value)) {
      return `This is not correct phone number ${value}`;
    }
  };

  const validateEmailOrEmpty = (value: string | undefined) => {
    if (value && value.length > 0) {
      return /^\S+@\S+$/.test(value) ? null : "Invalid email";
    } else {
      return null;
    }
  };

  const validateDeclaredValue = (value: string | undefined) => {
    if (value && Number(value) < 100) {
      return "Declared value must be at least $100";
    } else {
      return null;
    }
  };

  const packageForm = useForm({
    initialValues: packageData,
    validate: {
      weight: isNotEmpty("Enter package weight"),
      length: isNotEmpty("Enter package length"),
      width: isNotEmpty("Enter package width"),
      height: isNotEmpty("Enter package height"),
      senderLastName: isNotEmpty("Enter sender last name"),
      senderFirstName: isNotEmpty("Enter sender "),
      senderAddressLine1: isNotEmpty("Enter sender address"),
      senderPostcode: isNotEmpty("Enter sender postcode"),
      senderCity: isNotEmpty("Enter sender city"),
      senderState: isNotEmpty("Select sender state"),
      senderPhoneNumber: (value) => validatePhoneNumber(value),
      senderEmail: isEmail("Enter sender email"),
      senderAddressClassification: (value) =>
        senderAddressClassificationRadio && value === undefined
          ? "Please select"
          : null,
      recipientLastName: isNotEmpty("Enter recipient last name"),
      recipientFirstName: isNotEmpty("Enter recipient "),
      recipientAddressLine1: isNotEmpty("Enter recipient address"),
      recipientPostcode: isNotEmpty("Enter recipient postcode"),
      recipientCity: isNotEmpty("Enter recipient city"),
      recipientState: isNotEmpty("Select recipient state"),
      recipientPhoneNumber: (value) => validatePhoneNumber(value),
      // recipientEmail: isEmail("Enter recipient email"),
      recipientEmail: (value) => validateEmailOrEmpty(value),
      recipientAddressClassification: (value) =>
        recipientAddressClassificationRadio && value === undefined
          ? "Please select"
          : null,
      declaredValue: (value) => validateDeclaredValue(value),
      // declaredValue:  isInRange(
      //   { min: 100, max: 10000 },
      //   "Minimum value is $100",
      // ),
      // weight: hasLength({ min: 1, max: 10 }, 'Enter weight'),
      // leng: isNotEmpty('Enter your current job'),
      // email: isEmail('Invalid email'),
      // favoriteColor: matches(/^#([0-9a-f]{3}){1,2}$/, 'Enter a valid hex color'),
      // age: isInRange({ min: 18, max: 99 }, 'You must be 18-99 years old to register'),
    },
  });

  const checkRecipientAddress = (address: AddressValidation): boolean => {
    if (
      address.classification === "BUSINESS" ||
      address.classification === "RESIDENTIAL"
    ) {
      packageForm.values.recipientAddressClassification =
        address.classification;
      packageForm.values.recipientAddressClassificationByFedex = true;
      return true;
    } else {
      packageForm.values.recipientAddressClassification = undefined;
      packageForm.values.recipientAddressClassificationByFedex = false;
      recipientAddressClassificationRadioHandler.open();
      return false;
    }
  };

  const checkSenderAddress = (address: AddressValidation): boolean => {
    if (senderFormVisible) {
      if (
        address.classification === "BUSINESS" ||
        address.classification === "RESIDENTIAL"
      ) {
        packageForm.values.senderAddressClassification = address.classification;
        packageForm.values.senderAddressClassificationByFedex = true;
        return true;
      } else {
        senderAddressClassificationRadioHandler.open();
        return false;
      }
    }
    // Form not visible, so use last classification
    return true;
  };

  const validateAddress = async (values: Package) => {
    try {
      const response = await packageService.validateAddresses(values);
      const recipientAddressValidated = checkRecipientAddress(
        response.data["data"]["recipientAddress"],
      );
      const senderAddressValidated = checkSenderAddress(
        response.data["data"]["senderAddress"],
      );
      return recipientAddressValidated && senderAddressValidated;
    } catch {
      // TODO set some error label
      return false;
    }
  };

  const submitForm = async (values: Package) => {
    setIsValidatingAddress(true);
    if (!goToNextStepDirectly) {
      values.sender = senderId;
      values.recipient = recipient;
      values.useLastSender = !senderFormVisible;
      if (senderFormVisible) {
        // When user unset "Last Sender" so better create new Sender
        values.sender = undefined;
      }
      const addressValid = await validateAddress(values);
      if (addressValid) {
        setPackageData(values);
        nextStep();
      } else {
        setGoToNextStepDirectly(true);
      }
    } else {
      setPackageData(values);
      nextStep();
    }
    setIsValidatingAddress(false);
  };

  const autocompleteSetter = (values: any) => {
    packageForm.setValues(values);
  };

  const onZipChange = async (fieldName: string, value: string) => {
    if (value.length == 5) {
      const isSender = fieldName.startsWith("sender");
      if (isSender) {
        setIsLoadingSendertZip(true);
      } else {
        setIsLoadingRecipientZip(true);
      }
      try {
        const response = await packageService.getZip(value);
        if (isSender) {
          packageForm.setValues({
            senderCity: response.data["data"].city,
            senderState: response.data["data"].state,
          });
        } else {
          packageForm.setValues({
            recipientCity: response.data["data"].city,
            recipientState: response.data["data"].state,
          });
        }
      } catch {
        packageForm.setFieldError(fieldName, "Zip not found");
      } finally {
        setIsLoadingSendertZip(false);
        setIsLoadingRecipientZip(false);
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "recipientPostcode" || name === "senderPostcode") {
      onZipChange(name, value);
    }

    const recipientAddressFields = [
      "recipientAddressLine1",
      "recipientAddressLine2",
      "recipientPostcode",
      "recipientState",
      "recipientCity",
    ];
    if (
      recipientAddressClassificationRadio &&
      recipientAddressFields.includes(name)
    ) {
      recipientAddressClassificationRadioHandler.close();
      // if sender classification is on, go to next step
      setGoToNextStepDirectly(senderAddressClassificationRadio);
    }

    const senderAddressFields = [
      "senderAddressLine1",
      "senderAddressLine2",
      "senderPostcode",
      "senderState",
      "senderCity",
    ];
    if (
      senderAddressClassificationRadio &&
      senderAddressFields.includes(name)
    ) {
      senderAddressClassificationRadioHandler.close();
      // if recipient classification is on, go to next step
      setGoToNextStepDirectly(recipientAddressClassificationRadio);
    }
  };

  const clearDeclaredValue = () => {
    if (packageForm.values.additionalInsurance) {
      packageForm.setFieldValue("declaredValue", "100");
    }
    packageForm.setFieldValue(
      "additionalInsurance",
      !packageForm.values.additionalInsurance,
    );
  };

  return (
    <Box
      component="form"
      // maw={600}
      mx="auto"
      style={stylingObject.boxStyle}
      onSubmit={packageForm.onSubmit((values) => submitForm(values))}
      onChange={handleInputChange}
    >
      <Flex direction={"column"} gap={"md"}>
        <Title order={1} c={"#FF0000"} size={"h2"}>
          Enter parcel data
        </Title>
        <Divider my="sm" size={"sm"} />
        <Box className={classes.parcelDetails}>
          <Text
            className={classes.parcelDetailsTitle}
            c={"#FF0000"}
            fw={"600"}
            tt={"uppercase"}
            fz={"md"}
            mb={"sm"}
          >
            Parcel details
          </Text>
          <Divider my="xs" />
          <Flex justify="space-between" gap={"10px"}>
            <NumberInput
              label="Weight"
              description="lbs"
              placeholder="enter weight"
              withAsterisk
              leftSection={<IconWeight />}
              mt="md"
              {...packageForm.getInputProps("weight")}
            />
            <NumberInput
              label="Length"
              description="inch"
              placeholder="enter length"
              withAsterisk
              mt="md"
              leftSection={<IconDimensions />}
              {...packageForm.getInputProps("length")}
            />
            <NumberInput
              label="Width"
              description="inch"
              placeholder="enter width"
              withAsterisk
              mt="md"
              leftSection={<IconDimensions />}
              {...packageForm.getInputProps("width")}
            />
            <NumberInput
              label="Height"
              description="inch"
              placeholder="enter height"
              withAsterisk
              mt="md"
              leftSection={<IconDimensions />}
              {...packageForm.getInputProps("height")}
            />
          </Flex>
          <Flex gap={"10px"}>
            <Checkbox.Card
              className={classes.root}
              radius="sm"
              mt="md"
              checked={packageForm.values.additionalInsurance}
              onClick={clearDeclaredValue}
              w={350}
            >
              <Group align="flex-start" p={"sm"}>
                <Checkbox.Indicator mt={"sm"} />
                <NumberInput
                  allowNegative={false}
                  disabled={!packageForm.values.additionalInsurance}
                  label="Additional Insurance"
                  description="(By default, the package is Insured for $100)"
                  placeholder="$100"
                  prefix="$"
                  defaultValue={100.0}
                  decimalScale={2}
                  hideControls
                  {...packageForm.getInputProps("declaredValue")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Group>
            </Checkbox.Card>
          </Flex>
          <Flex gap={"100px"} mt={"lg"}>
            <Switch
              defaultChecked={false}
              label="Signature required"
              {...packageForm.getInputProps("signatureRequired")}
              checked={packageForm.values.signatureRequired}
            />
          </Flex>
        </Box>
        <Box className={classes.parcelDetails}>
          <Flex gap={"lg"} align={"center"}>
            <Text
              className={classes.parcelDetailsTitle}
              c={"#FF0000"}
              fw={"600"}
              tt={"uppercase"}
            >
              Sender
            </Text>
            <Switch
              disabled={!lastSenderString}
              checked={senderFormVisible}
              label="Use different sender"
              onClick={senderFormVisibleHandler.toggle}
            />
          </Flex>
          <Divider my="xs" />
          <Collapse in={!senderFormVisible}>
            <Text>{lastSenderString}</Text>
          </Collapse>
          <Collapse in={senderFormVisible}>
            <Flex justify="space-between" gap={"10px"}>
              <SenderRecipientAutocomplete
                values={packageForm.values}
                setValues={autocompleteSetter}
                setPersonId={setSenderId}
                who={"sender"}
                fieldName={"senderFirstName"}
                searchField="first_name"
                label="First Name"
                placeholder=""
                withAsterisk
                mt="md"
                flex={1}
                {...packageForm.getInputProps("senderFirstName")}
              />
              <SenderRecipientAutocomplete
                values={packageForm.values}
                setValues={autocompleteSetter}
                setPersonId={setSenderId}
                who={"sender"}
                fieldName="senderLastName"
                searchField="last_name"
                label="Last Name"
                placeholder=""
                withAsterisk
                mt="md"
                flex={1}
                {...packageForm.getInputProps("senderLastName")}
              />
              <TextInput
                label="Company"
                placeholder="Company"
                mt="md"
                flex={1}
                {...packageForm.getInputProps("senderCompany")}
                name="senderCompany"
              />
            </Flex>
            <TextInput
              label="Address"
              placeholder="Address"
              withAsterisk
              mt="md"
              {...packageForm.getInputProps("senderAddressLine1")}
              name="senderAddressLine1"
            />
            <TextInput
              label="Address"
              placeholder="Address (second line)"
              mt="md"
              {...packageForm.getInputProps("senderAddressLine2")}
              name="senderAddressLine2"
            />
            <Flex justify="stretch" align={"stretch"} gap={"10px"}>
              <TextInput
                label="ZIP code"
                placeholder=""
                withAsterisk
                mt="md"
                flex={1}
                {...packageForm.getInputProps("senderPostcode")}
                name="senderPostcode"
                width={"100%"}
              />
              <TextInput
                label="City"
                placeholder="City"
                withAsterisk
                mt="md"
                flex={1}
                {...packageForm.getInputProps("senderCity")}
                name="senderCity"
              />
              <Select
                label="State"
                placeholder="Select state"
                withAsterisk
                mt="md"
                flex={1}
                data={statesToSelect}
                allowDeselect={false}
                searchable
                nothingFoundMessage="Nothing found..."
                {...packageForm.getInputProps("senderState")}
                name="senderState"
              />
              <TextInput
                label="Country"
                placeholder=""
                mt="md"
                disabled
                style={stylingObject.countryInput}
                {...packageForm.getInputProps("senderCountry")}
              />
            </Flex>
            <Flex justify="space-between" gap={"10px"}>
              <InputWrapper
                mt="md"
                label="Phone number"
                withAsterisk
                flex={1}
                error={packageForm.getInputProps("senderPhoneNumber").error}
              >
                <Input
                  component={IMaskInput}
                  mask="+1 (000) 000-0000"
                  placeholder=""
                  {...packageForm.getInputProps("senderPhoneNumber")}
                />
              </InputWrapper>
              <TextInput
                label="Email"
                placeholder="enter sender email"
                withAsterisk
                mt="md"
                miw={400}
                flex={1}
                {...packageForm.getInputProps("senderEmail")}
              />
            </Flex>
            <Flex justify="center" mt="lg" gap={"10px"}>
              <Collapse in={senderAddressClassificationRadio}>
                <Radio.Group
                  {...packageForm.getInputProps("senderAddressClassification")}
                  name="senderAddressClassification"
                  label="Select sender Address type"
                  description="You have to provide if it is BUSINESS or RESIDENTIAL address"
                  withAsterisk
                >
                  <Group mt="xs">
                    <Radio value={"BUSINESS"} label={"Business"} />
                    <Radio value={"RESIDENTIAL"} label={"Residential"} />
                  </Group>
                </Radio.Group>
              </Collapse>
            </Flex>
          </Collapse>
        </Box>
        <Box className={classes.parcelDetails}>
          <Text
            className={classes.parcelDetailsTitle}
            c={"#FF0000"}
            fw={"600"}
            tt={"uppercase"}
            fz={"md"}
            mb={"sm"}
          >
            Recipient
          </Text>
          <Divider my="xs" />
          <Flex justify="space-between" gap={"10px"}>
            <SenderRecipientAutocomplete
              values={packageForm.values}
              setValues={autocompleteSetter}
              setPersonId={setRecipient}
              who={"recipient"}
              fieldName={"recipientFirstName"}
              searchField="first_name"
              label="Recipient First Name"
              placeholder=""
              withAsterisk
              mt="md"
              flex={1}
              {...packageForm.getInputProps("recipientFirstName")}
            />
            <SenderRecipientAutocomplete
              values={packageForm.values}
              setValues={autocompleteSetter}
              setPersonId={setRecipient}
              who={"recipient"}
              fieldName={"recipientLastName"}
              searchField="last_name"
              label="Recipient Last Name"
              placeholder=""
              withAsterisk
              mt="md"
              flex={1}
              {...packageForm.getInputProps("recipientLastName")}
            />
            <TextInput
              label="Company"
              placeholder="Company"
              mt="md"
              flex={1}
              {...packageForm.getInputProps("recipientCompany")}
              name="recipientCompany"
            />
          </Flex>
          <TextInput
            label="Recipient address"
            placeholder=""
            withAsterisk
            mt="md"
            {...packageForm.getInputProps("recipientAddressLine1")}
            name="recipientAddressLine1"
          />
          <TextInput
            label="Recipient address (second line)"
            placeholder=""
            mt="md"
            {...packageForm.getInputProps("recipientAddressLine2")}
            name="recipientAddressLine2"
          />
          <Flex justify="space-between" gap={"10px"}>
            <TextInput
              label="Recipient ZIP code"
              placeholder=""
              withAsterisk
              mt="md"
              flex={1}
              {...packageForm.getInputProps("recipientPostcode")}
              name="recipientPostcode"
            />
            {/* {isLoadingRecipientZip && <Loader size={18} />} */}
            <TextInput
              label="Recipient city"
              placeholder=""
              withAsterisk
              mt="md"
              flex={1}
              {...packageForm.getInputProps("recipientCity")}
              name="recipientCity"
            />
            <Select
              label="Recipient state"
              placeholder="Select state"
              withAsterisk
              mt="md"
              flex={1}
              data={statesToSelect}
              allowDeselect={false}
              searchable
              nothingFoundMessage="Nothing found..."
              {...packageForm.getInputProps("recipientState")}
              name="recipientState"
            />
            <TextInput
              label="Country"
              placeholder=""
              mt="md"
              flex={1}
              disabled
              style={stylingObject.countryInput}
              {...packageForm.getInputProps("recipientCountry")}
            />
          </Flex>
          <Flex justify="space-between" gap={"10px"}>
            <InputWrapper
              mt="md"
              label="Recipient phone number"
              withAsterisk
              flex={1}
              error={packageForm.getInputProps("recipientPhoneNumber").error}
            >
              <Input
                component={IMaskInput}
                mask="+1 (000) 000-0000"
                label="Recipient phone number"
                placeholder=""
                onAccept={
                  packageForm.getInputProps("recipientPhoneNumber").onChange
                }
                {...packageForm.getInputProps("recipientPhoneNumber")}
              />
            </InputWrapper>
            <TextInput
              label="Recipient email"
              placeholder="enter recipient email"
              mt="md"
              flex={1}
              miw={400}
              {...packageForm.getInputProps("recipientEmail")}
            />
          </Flex>
          <Flex justify="center" mt="lg" gap={"10px"}>
            <Collapse in={recipientAddressClassificationRadio}>
              <Radio.Group
                {...packageForm.getInputProps("recipientAddressClassification")}
                name="recipientAddressClassification"
                label="Select recipient Address type"
                description="You have to provide if it is BUSINESS or RESIDENTIAL address"
                withAsterisk
              >
                <Group mt="xs">
                  <Radio value={"BUSINESS"} label={"Business"} />
                  <Radio value={"RESIDENTIAL"} label={"Residential"} />
                </Group>
              </Radio.Group>
            </Collapse>
          </Flex>
        </Box>
        <Group justify="center" mt="xl">
          <Button
            type="submit"
            loading={isValidatingAddress}
            loaderProps={{ type: "dots" }}
          >
            Validate package data
          </Button>
        </Group>
      </Flex>
    </Box>
  );
};

export default AddPackageForm;

const stylingObject = {
  boxStyle: {
    marginBottom: "30px",
  },
  sectionTitle: {
    paddingTop: "20px",
    textAlign: "left",
  },
  countryInput: {
    maxWidth: "60px",
  },
};
