import { useRef, useState } from "react";
import { Combobox, Loader, TextInput, useCombobox } from "@mantine/core";
import { Package, SenderRecipient } from "../Interfaces";
import senderService from "../services/sender.service";
import recipientService from "../services/recipient.service";

const getAsyncData = (
  queryData: Package,
  searchField: string,
  query: string,
  signal: AbortSignal,
  who: string,
) => {
  return new Promise<SenderRecipient[]>(async (resolve, reject) => {
    signal.addEventListener("abort", () => {
      reject(new Error("Request aborted"));
    });
    const response =
      who === "sender"
        ? await senderService.searchSender(queryData, searchField, query)
        : await recipientService.searchRecipient(queryData, searchField, query);
    resolve(response.data["data"]);
  });
};

type Props = {
  // setValues: (arg0: PackageSenderData | PackageRecipientData | null) => void;
  values: Package;
  setValues: (arg0: any | null) => void;
  setPersonId: (arg0: string) => void;
  who: string;
  fieldName: string;
  searchField: string;
  // All other props
  [rest: string]: any;
};

export function SenderRecipientAutocomplete(props: Props) {
  const { values, setValues, setPersonId, who, fieldName, searchField, ...rest } = props;
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SenderRecipient[] | null>(null);
  // const [value, setValue] = useState("");
  const [empty, setEmpty] = useState(false);
  const abortController = useRef<AbortController>();

  const fetchOptions = (query: string) => {
    abortController.current?.abort();
    abortController.current = new AbortController();
    setLoading(true);

    getAsyncData(values, searchField, query, abortController.current.signal, who)
      .then((result) => {
        setData(result);
        setLoading(false);
        setEmpty(result.length === 0);
        abortController.current = undefined;
      })
      .catch(() => {});
  };

  const options = (data || []).map((item) => (
    <Combobox.Option value={item.id} key={item.id}>
      {item.lastName} {item.firstName}, {item.city}, {item.postcode}
    </Combobox.Option>
  ));

  const onOptionSubmit = (optionValue: string) => {
    const personData = data?.find((item) => item.id === optionValue);
    if (personData && who === "recipient") {
      setValues({
        recipientLastName: personData.lastName,
        recipientFirstName: personData.firstName,
        recipientCountry: personData.country,
        recipientAddressLine1: personData.addressLine1,
        recipientAddressLine2: personData.addressLine2,
        recipientPostcode: personData.postcode,
        recipientCity: personData.city,
        recipientState: personData.state,
        recipientPhoneNumber: personData.phoneNumber,
        recipientEmail: personData.email,
        recipientAddressClassification: personData.addressClassification,
        recipientAddressClassificationByFedex:
          personData.addressClassificationByFedex,
      });
      setPersonId(personData.id);
    }
    if (personData && who === "sender") {
      setValues({
        senderLastName: personData.lastName,
        senderFirstName: personData.firstName,
        senderCountry: personData.country,
        senderAddressLine1: personData.addressLine1,
        senderAddressLine2: personData.addressLine2,
        senderPostcode: personData.postcode,
        senderCity: personData.city,
        senderState: personData.state,
        senderPhoneNumber: personData.phoneNumber,
        senderEmail: personData.email,
        senderAddressClassification: personData.addressClassification,
        senderAddressClassificationByFedex:
          personData.addressClassificationByFedex,
      });
      setPersonId(personData.id);
    }
    combobox.closeDropdown();
  };

  return (
    <Combobox
      // width={"1000px"}
      onOptionSubmit={onOptionSubmit}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          {...rest}
          // width={"1000px"}
          style={{
            minWidth: "300px",
          }}
          onChange={(event) => {
            const newValues: { [index: string]: string } = {};
            newValues[fieldName] = event.currentTarget.value;
            setValues(newValues);
            // setValue(event.currentTarget.value);
            fetchOptions(event.currentTarget.value);
            combobox.resetSelectedOption();
            combobox.openDropdown();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={(event) => {
            combobox.openDropdown();
            if (data === null) {
              fetchOptions(event.currentTarget.value);
            }
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSection={loading && <Loader size={18} />}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {empty && <Combobox.Empty>No results found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
