import { useState } from "react";

import { Box, Button, Flex, Group, Stepper, Text } from "@mantine/core";
import AddPackageSummary from "./AddPackageSummary";
import classes from "./Worldco.module.css";

import { Package } from "../Interfaces";
import AddPackageForm from "./AddPackageForm";
import packageService from "../services/package.service";
import { parsePhoneNumber } from "react-phone-number-input/input";
import { useWindowScroll } from "@mantine/hooks";

const AddPackage = () => {
  const initialValues = {
    weight: "",
    length: "",
    width: "",
    height: "",
    senderLastName: "",
    senderFirstName: "",
    senderCountry: "US",
    senderAddressLine1: "",
    senderAddressLine2: "",
    senderPostcode: "",
    senderCity: "",
    senderState: "",
    senderPhoneNumber: "",
    senderEmail: "",
    recipientLastName: "",
    recipientFirstName: "",
    recipientCountry: "US",
    recipientAddressLine1: "",
    recipientAddressLine2: "",
    recipientPostcode: "",
    recipientCity: "",
    recipientState: "",
    recipientPhoneNumber: "",
    recipientEmail: "",
    useLastSender: true,
  };
  const [active, setActive] = useState(0);
  const [packageData, setPackageData] = useState<Package>(initialValues);
  const [scroll, scrollTo] = useWindowScroll();
  const [isCreatingPackage, setIsCreatingPackage] = useState(false);
  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current));
    scrollTo({ y: 0 })
  }
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const reinitialize = () => {
    cleanUp();
    setPackageData(initialValues);
    setActive(0);
    scrollTo({ y: 0 })
  };

  const addPackage = () => {
    if (packageData) {
      const recipientPhoneNumber = parsePhoneNumber(
        packageData["recipientPhoneNumber"],
      );
      const senderPhoneNumber = parsePhoneNumber(
        packageData["senderPhoneNumber"],
      );
      if (recipientPhoneNumber) {
        packageData["recipientPhoneNumber"] = recipientPhoneNumber.number;
      }
      if (senderPhoneNumber) {
        packageData["senderPhoneNumber"] = senderPhoneNumber.number;
      }
    }
    addPackageCall(packageData);
  };

  const addPackageCall = async (packageData: Package) => {
    setIsCreatingPackage(true);
    try {
      const packageResult = await packageService.addPackage(packageData);
      setPackageData(packageResult.data["data"]);
      nextStep();
    } catch {
      // TODO
    }
    finally {
      setIsCreatingPackage(false);
    }
  };

  let printIframe: any = null;
  let blobUrls: any = [];

  const printBase64PDF = async () => {
    if (!printIframe) {
      printIframe = document.createElement("iframe");
      document.body.appendChild(printIframe);

      printIframe.style.position = "absolute";
      printIframe.style.top = "0";
      printIframe.style.left = "-1000px";

      printIframe.onload = () => {
        setTimeout(() => {
          printIframe?.focus();
          printIframe?.contentWindow?.print();
        }, 100);
      };
    }

    // convert base64 to a blob - using data URLs directly produces CORS errors
    const resp = await fetch(
      `data:application/pdf;base64,${packageData.label}`,
    );
    const blob = await resp.blob();
    const url = URL.createObjectURL(blob);
    blobUrls.push(url);

    printIframe.src = url;
  };

  const cleanUp = () => {
    printIframe?.remove();
    printIframe = undefined;
    blobUrls?.forEach(URL.revokeObjectURL);
    blobUrls = [];
  };

  return (
    <Box bg={"#F1F3F5"}>
      <Stepper
        active={active}
        onStepClick={setActive}
        orientation="horizontal"
        classNames={{ stepIcon: classes.stepIcon }}
        radius={"xs"}
        size="sm"
      >
        <Stepper.Step
          label="First step"
          description="Enter parcel data"
          allowStepSelect={false}
          style={{
            borderRadius: 0,
          }}
        >
          <AddPackageForm
            packageData={packageData}
            setPackageData={setPackageData}
            nextStep={nextStep}
          />
        </Stepper.Step>
        <Stepper.Step label="Second step" description="Validate parcel data">
          <AddPackageSummary
            packageData={packageData}
            setPackageData={setPackageData}
            prevStep={prevStep}
            addPackage={addPackage}
            isCreatingPackage={isCreatingPackage}
          />
        </Stepper.Step>
        <Stepper.Step label="Final step" description="Print label">
          {/* <Flex align="center" direction="column"> */}
          <Flex align="center" direction="column">
            <Text mb={"sm"}>
              Fedex package number: <b>{packageData.number}</b>
            </Text>
            <embed
              width={"75%"}
              height={"750em"}
              src={`data:application/pdf;base64,${packageData.label}`}
            />
            {/* <object
              width={"85%"}
              height={"750em"}
              data={`data:application/pdf;base64,${packageData.label}`} type="application/pdf"
            /> */}
          </Flex>
          <Group justify="center" mt="xl" align="flex-end">
            <Button
              variant="default"
              disabled={active === 0}
              onClick={reinitialize}
            >
              Create new package
            </Button>
            <Button onClick={printBase64PDF}>Print Label</Button>
          </Group>
          {/* </Flex> */}
        </Stepper.Step>
        <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed>
      </Stepper>
    </Box>
  );
};

export default AddPackage;
