import {
  QueryClientProvider,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Box, Text } from "@mantine/core";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import recipientService from "../services/recipient.service";
import { SenderRecipient } from "../Interfaces";

const useRecipients = () => {
  return useQuery({
    queryKey: ["id"],
    queryFn: async () => {
      const { data } = await recipientService.getRecipients();
      console.log(data);
      return data["data"];
    },
    placeholderData: keepPreviousData, //useful for paginated queries by keeping data from previous pages on screen while fetching the next page
    // staleTime: 30_000, //don't refetch previously viewed pages until cache is more than 30 seconds old
  });
};

const RecipientsPage = () => {
  const { status, data, error, isFetching } = useRecipients();
  const queryClient = useQueryClient();
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: "firstName",
          header: "First name",
          // muiTableHeadCellProps: { sx: { color: "green" } },
        },
        {
          accessorKey: "lastName",
          header: "Last name",
          muiTableHeadCellProps: { sx: { color: "green" } },
        },
        {
          accessorKey: "postcode",
          header: "postcode",
          muiTableHeadCellProps: { sx: { color: "green" } },
        },
        {
          accessorKey: "city",
          header: "city",
          muiTableHeadCellProps: { sx: { color: "green" } },
        },
        {
          accessorKey: "state",
          header: "state",
          muiTableHeadCellProps: { sx: { color: "green" } },
        },
      ] as MRT_ColumnDef<SenderRecipient>[],
    [],
  );
  return (
    <QueryClientProvider client={queryClient}>
      <MantineReactTable
        columns={columns}
        data={data ? data : []}
        displayColumnDefOptions={{
          "mrt-row-numbers": {
            size: 10,
          },
          "mrt-row-expand": {
            size: 10,
          },
        }}
        renderDetailPanel={({ row }) => (
          <Box
            style={{
              display: "grid",
              margin: "auto",
              gridTemplateColumns: "1fr 1fr",
              width: "100%",
            }}
          >
            <Text>Address: {row.original.addressLine1}</Text>
            <Text>Address (second line): {row.original.addressLine2}</Text>
            <Text>Phone Number: {row.original.phoneNumber}</Text>
            <Text>Email: {row.original.email}</Text>
            <Text>Country: {row.original.country}</Text>
          </Box>
        )}
        // // enableColumnActions={!onlyOptions}
        // enableColumnFilterModes
        enablePagination={true}
        enableColumnPinning
        // enableRowNumbers
        // enableBottomToolbar={false}
        // // enableTopToolbar={!onlyOptions}
        // initialState={{
        //   columnVisibility: { description: false },
        //   density: 'xs',
        //   showGlobalFilter: true,
        //   sorting: [{ id: 'tableInstanceAPI', desc: false }],
        // }}
        mantineSearchTextInputProps={{
          placeholder: "Search recipient",
          style: { minWidth: "18rem" },
          variant: "filled",
        }}
        // mantinePaperProps={{
        //   style: { marginBottom: '24px' },
        //   id: onlyOptions
        //     ? 'relevant-table-instance-apis-table'
        //     : 'table-instance-apis-table',
        // }}
        positionGlobalFilter="left"
        // renderDetailPanel={({ row }) => (
        //   <Text color={row.original.description ? 'teal' : 'gray'}>
        //     {row.original.description || 'No Description Provided... Yet...'}
        //   </Text>
        // )}
        // rowNumberMode="static"
        // onColumnPinningChange={setColumnPinning}
        // state={{ columnPinning }}
      />
    </QueryClientProvider>
  );
};

export default RecipientsPage;
