import apiAuth from "./apiAuth";
import authHeader from "./auth-header";

import { API_URL } from "./common.service";

class PackageService {
  addPackage(packageData: any) {
    return apiAuth.post(API_URL + `packages/`, packageData, {
      headers: authHeader(),
    });
  }
  getRates(packageData: any) {
    return apiAuth.post(API_URL + `packages/rates/`, packageData, {
      headers: authHeader(),
    });
  }
  getPackages() {
    return apiAuth.get(API_URL + `packages/`, {
      headers: authHeader(),
    });
  }
  validateAddresses(packageData: any) {
    return apiAuth.post(API_URL + `packages/validate-address/`, packageData, {
      headers: authHeader(),
    });
  }
  getZip(postcode: string) {
    return apiAuth.get(API_URL + `zips/${postcode}/`, {
      headers: authHeader(),
    });
  }
  cancelPackage(packageId: string) {
    return apiAuth.patch(API_URL + `packages/${packageId}/cancel/`, {
      headers: authHeader(),
    });
  }
}

export default new PackageService();
