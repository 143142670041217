import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  Radio,
  Space,
  Table,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { State } from "country-state-city";
import { Package, PackageType } from "../Interfaces";
import packageService from "../services/package.service";
import moment from "moment";
import classes from "./Worldco.module.css";

type Props = {
  nextStep?: () => void;
  prevStep: () => void;
  addPackage: () => void;
  packageData: Package;
  setPackageData: (arg0: Package) => void;
  isCreatingPackage: boolean;
};

const AddPackageSummary = ({
  packageData,
  setPackageData,
  addPackage,
  prevStep,
  isCreatingPackage,
}: Props) => {
  const [selectedPackageType, setSelectedPackageType] = useState("");
  const [packageTypes, setPackageTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [billingWeightDescription, setBillingWeightDescription] = useState("");

  useEffect(() => {
    const getPackageTypes = async () => {
      try {
        const response = await packageService.getRates(packageData);
        setPackageTypes(response.data["data"]);
        const billingWeight = response.data["data"][0]["ratedShipmentDetails"]["billingWeight"]["value"];
        if(billingWeight != packageData.weight) {
          setBillingWeightDescription(`(${billingWeight} lbs)`);
        }
        else {
          setBillingWeightDescription("");
        }
        setIsLoading(false);
      } catch {
        // TODO set some error label
      }
    };
    getPackageTypes();
  }, []);

  const getStateName = (stateCode: string) => {
    const state = State.getStateByCodeAndCountry(stateCode, "US");
    return state?.name;
  };

  const setParcelType = (event: string | "") => {
    packageData.packageType = event;
    setPackageData(packageData);
    setSelectedPackageType(event);
  };

  const onRowClickHandler = (event: {
    currentTarget: { getAttribute: (arg0: string) => any };
  }) => {
    const key = event.currentTarget.getAttribute("property");
    setParcelType(key ? key : "");
  };

  const packageTypeOptions = packageTypes.map((packageType: PackageType) => (
    <Table.Tr
      property={packageType.serviceType}
      key={packageType.serviceType}
      onClick={onRowClickHandler}
    >
      <Table.Td>
        <Radio
          value={packageType.serviceType}
          label={packageType.serviceName}
          disabled={isCreatingPackage}
        />
      </Table.Td>
      <Table.Td>
        {moment(packageType.deliveryDate).format("dddd, MMMM Do, h:mm a")}
      </Table.Td>
      <Table.Td>
        ${packageType.ratedShipmentDetails.worldcoPrice}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      {packageData && (
        <Box
          component="form"
          // maw={600}
          mx="auto"
          style={stylingObject.boxStyle}
        >
          <Card shadow="sl" padding="lg" radius="md" withBorder>
            <Text
              className={classes.parcelDetailsTitle}
              c={"#FF0000"}
              fw={"600"}
              tt={"uppercase"}
              fz={"md"}
              mb={"sm"}
            >
              Parcel details
            </Text>
            <Divider my="xs" />
            <Group justify="center" grow>
              <Text size="sl">
                Weight: <b>{packageData.weight} lbs {billingWeightDescription}</b>
              </Text>
              <Text size="sl">
                Height: <b>{packageData.height} inch</b>
              </Text>
              <Text size="sl">
                Length: <b>{packageData.length} inch</b>
              </Text>
              <Text size="sl">
                Width: <b>{packageData.width} inch</b>
              </Text>
              <Checkbox
                checked={packageData.signatureRequired}
                label="Signature required"
              />
              {packageData.declaredValue && (
                <Text size="sl">
                  Declared Value: <b>${packageData.declaredValue}</b>
                </Text>
              )}
            </Group>

            {isError ? (
              <Box>
                <Text>
                  There is a problem with get rates for package. Try again in a
                  minute
                </Text>
              </Box>
            ) : (
              <Card>
                <Radio.Group
                  value={selectedPackageType}
                  onChange={setParcelType}
                  name="packageType"
                  label="Select package type"
                  // description="Select description"
                  withAsterisk
                >
                  <LoadingOverlay
                    visible={isLoading}
                    zIndex={1000}
                    overlayProps={{ radius: "sm", blur: 2 }}
                  />
                  <Table highlightOnHover>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Package type</Table.Th>
                        <Table.Th>Delivery on</Table.Th>
                        <Table.Th>Price</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{packageTypeOptions}</Table.Tbody>
                  </Table>
                </Radio.Group>
              </Card>
            )}
          </Card>
          <Group justify="center" mt="xl" gap="xl">
            <Card shadow="sl" padding="lg" radius="md" withBorder flex={1}>
              <Group justify="space-between" mt="md" mb="xs">
                <Text
                  className={classes.parcelDetailsTitle}
                  c={"#FF0000"}
                  fw={"600"}
                  tt={"uppercase"}
                  fz={"md"}
                  mb={"sm"}
                >
                  Sender
                </Text>
              </Group>
              <Divider my="xs" />
              <Table withRowBorders={false} verticalSpacing={"0px"}>
                <Table.Tr>
                  <Table.Td c="dimmed">Last name:</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderLastName}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">First name</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderFirstName}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Address</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderAddressLine1}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Address second line</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderAddressLine2}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">ZIP code</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderPostcode}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">City</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderCity}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">State</Table.Td>
                  <Table.Td>
                    <Text size="sl">
                      {getStateName(packageData.senderState)}
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Address type</Table.Td>
                  <Table.Td>
                    <Text size="sl">
                      {packageData.senderAddressClassification?.toLowerCase()}
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Phone Number</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderPhoneNumber}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Email</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.senderEmail}</Text>
                  </Table.Td>
                </Table.Tr>
              </Table>
            </Card>
            <Card shadow="sl" padding="lg" radius="md" withBorder flex={1}>
              <Group justify="space-between" mt="md" mb="xs">
                <Text
                  className={classes.parcelDetailsTitle}
                  c={"#FF0000"}
                  fw={"600"}
                  tt={"uppercase"}
                  fz={"md"}
                  mb={"sm"}
                >
                  Recipient
                </Text>
              </Group>
              <Divider my="xs" />
              <Table
                withRowBorders={false}
                verticalSpacing={"0px"}
              >
                <Table.Tr>
                  <Table.Td c="dimmed">Last name:</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientLastName}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">First name</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientFirstName}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Address</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientAddressLine1}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Address second line</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientAddressLine2}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">ZIP code</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientPostcode}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">City</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientCity}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">State</Table.Td>
                  <Table.Td>
                    <Text size="sl">
                      {getStateName(packageData.recipientState)}
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Address type</Table.Td>
                  <Table.Td>
                    <Text size="sl">
                      {packageData.recipientAddressClassification?.toLowerCase()}
                    </Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Phone Number</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientPhoneNumber}</Text>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td c="dimmed">Email</Table.Td>
                  <Table.Td>
                    <Text size="sl">{packageData.recipientEmail}</Text>
                  </Table.Td>
                </Table.Tr>
              </Table>
            </Card>
          </Group>
          <Group justify="center" mt="xl">
            <Button variant="default" onClick={prevStep} disabled={isCreatingPackage}>
              Back
            </Button>
            <Button disabled={selectedPackageType === ""} onClick={addPackage} loading={isCreatingPackage} loaderProps={{ type: 'dots' }}>
              Confirm package
            </Button>
          </Group>
        </Box>
      )}
    </>
  );
};

export default AddPackageSummary;

const stylingObject = {
  boxStyle: {
    marginBottom: "30px",
  },
};
