import { Package } from "../Interfaces";
import apiAuth from "./apiAuth";
import authHeader from "./auth-header";

import { API_URL } from "./common.service";

class SenderService {
  getSenders() {
    return apiAuth.get(API_URL + `senders/`, {
      headers: authHeader(),
    });
  }
  getLast() {
    return apiAuth.get(API_URL + `senders/last/`, {
      headers: authHeader(),
    });
  }
  searchSender(queryData: Package, searchField: string, query: string) {
    let searchQuery = new URLSearchParams;
    if (queryData.senderPostcode) {
      searchQuery.append('postcode', queryData.senderPostcode)
    }
    if (queryData.senderLastName) {
      searchQuery.append('last_name', queryData.senderLastName)
    }
    if (queryData.senderFirstName) {
      searchQuery.append('first_name', queryData.senderFirstName)
    }
    searchQuery.set(searchField, query)
    return apiAuth.get(API_URL + `senders/?${searchQuery}`, {
      headers: authHeader(),
    });
  }
}

export default new SenderService();
