import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Select,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import userService from "../services/user.service";

const paperLabelsTypes = [
  "PAPER_4X6",
  "PAPER_4X675",
  "PAPER_4X8",
  "PAPER_4X9",
  "PAPER_7X475",
  "PAPER_85X11_BOTTOM_HALF_LABEL",
  "PAPER_85X11_TOP_HALF_LABEL",
  "PAPER_LETTER",
];
const stockLabelsTypes = [
  "STOCK_4X6",
  "STOCK_4X675_LEADING_DOC_TAB",
  "STOCK_4X675_TRAILING_DOC_TAB",
  "STOCK_4X8",
  "STOCK_4X9",
  "STOCK_4X9_LEADING_DOC_TAB",
  "STOCK_4X9_TRAILING_DOC_TAB",
  "STOCK_4X85_TRAILING_DOC_TAB",
  "STOCK_4X105_TRAILING_DOC_TAB",
];
const pickupTypes = [
  {
    value: "DROPOFF",
    label: "Drop Off to FedEx Office",
  },
  {
    value: "PICKUP",
    label: "Request pickup by Fedex",
  },
];

const UserSettingsPage = () => {
  const [selectedTypesGroup, setSelectedTypesGroup] = useState("PAPER");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const submitForm = async (values: any) => {
    setIsLoading(true);
    try {
      if (userData && userData["id"]) {
        await userService.saveUserData(userData["id"], values);
      }
    } catch {
      console.log("some problem");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      try {
        const response = await userService.getUserData();
        setUserData(response.data["data"]);
        const labelType = response.data["data"]["labelType"];
        setSelectedTypesGroup(labelType.substring(0, 5));
        form.setFieldValue("labelType", labelType);
        form.setFieldValue("pickupType", response.data["data"]["pickupType"]);
      } catch {
        // TODO
      } finally {
        setIsLoading(false);
      }
    };
    getUser();
  }, []);

  const form = useForm({
    // initialValues: packageData,
  });

  const changeLabelTypes = (labelType: string) => {
    setSelectedTypesGroup(labelType);
    form.setFieldValue("labelType", null);
  };

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((values) => submitForm(values))}
    >
      <Card>
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex gap={"10px"} direction={"column"}>
          <Box ml={"sm"}>
            <Text size="sm" fw={"500"}>
              Package label print format:{" "}
            </Text>
            <Flex align="center" gap={"10px"}>
              <SegmentedControl
                value={selectedTypesGroup}
                onChange={changeLabelTypes}
                data={[
                  { label: "Standard Printer", value: "PAPER" },
                  { label: "Label Printer", value: "STOCK" },
                ]}
              />
              <Select
                placeholder="Select format"
                data={
                  selectedTypesGroup === "PAPER"
                    ? paperLabelsTypes
                    : stockLabelsTypes
                }
                clearable
                searchable
                checkIconPosition="right"
                ml="md"
                styles={{ wrapper: { width: 400 } }}
                maxDropdownHeight={500}
                {...form.getInputProps("labelType")}
              />
            </Flex>
          </Box>
          <Select
            label="Select pickup type"
            placeholder="Select pickup type"
            data={pickupTypes}
            checkIconPosition="right"
            ml="md"
            styles={{ wrapper: { width: 400 } }}
            maxDropdownHeight={500}
            {...form.getInputProps("pickupType")}
          />
        </Flex>
        <Group justify="center" align="bottom" mt="xl">
          <Button type="submit">Save</Button>
        </Group>
      </Card>
    </Box>
  );
};

export default UserSettingsPage;
