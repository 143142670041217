import { Route, Routes } from "react-router-dom";
import NotFound from "./NotFound";
import AddPackage from "./AddPackage";
import LoginPage from "./LoginPage";
import PackagesPage from "./PackagesPage";
import SendersPage from "./SendersPage";
import RecipientsPage from "./RecipientsPage";
import UserSettingsPage from "./UserSettings";

const RouteSwitcher = () => {
  return (
    <Routes>
      <Route path="/" element={<AddPackage />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/add" element={<AddPackage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/packages" element={<PackagesPage />} />
      <Route path="/senders" element={<SendersPage />} />
      <Route path="/recipients" element={<RecipientsPage />} />
      <Route path="/settings" element={<UserSettingsPage />} />
    </Routes>
  );
};

export default RouteSwitcher;
