import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider, createTheme, useMantineTheme, Stepper, rem } from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import "@mantine/core/styles.css";
import { ModalsProvider } from "@mantine/modals";

// const theme = useMantineTheme();
// theme.colors
const theme = createTheme({
  /** Put your mantine theme override here */
  primaryColor: "red",
  // headings: {
  //   // properties for all headings
  //   // fontWeight: '400',
  //   // fontFamily: 'Roboto',
  //   // properties for individual headings, all of them are optional
  //   // sizes: {
  //   //   h1: {
  //   //     fontWeight: '100',
  //   //     fontSize: rem(36),
  //   //     lineHeight: '1.4',
  //   //   },
  //   //   h2: {
  //   //     fontSize: rem(30),
  //   //     lineHeight: '1.5',
        
  //   //   },
  //   //   // h6: { fontWeight: '900' },
  //   // },
  // },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme} defaultColorScheme="light">
      <ModalsProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
