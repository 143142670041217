import { Package } from "../Interfaces";
import apiAuth from "./apiAuth";
import authHeader from "./auth-header";

import { API_URL } from "./common.service";

class RecipientService {
  getRecipients() {
    return apiAuth.get(API_URL + `recipients/`, {
      headers: authHeader(),
    });
  }
  searchRecipient(queryData: Package, searchField: string, query: string) {
    let searchQuery = new URLSearchParams;
    if (queryData.recipientPostcode) {
      searchQuery.append('postcode', queryData.recipientPostcode)
    }
    if (queryData.recipientLastName) {
      searchQuery.append('last_name', queryData.recipientLastName)
    }
    if (queryData.recipientFirstName) {
      searchQuery.append('first_name', queryData.recipientFirstName)
    }
    searchQuery.set(searchField, query)
    return apiAuth.get(API_URL + `recipients/?${searchQuery}`, {
      headers: authHeader(),
    });
  }
}

export default new RecipientService();
